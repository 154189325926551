<!--
 * 登录页面
-->

<template>
  <div>
    <!-- <div class="page-bg"></div> -->
    <div class="head" v-if="showInvite && activityId == 1">
      <div class="head-title">顶级国漫IP数字藏品即将开售</div>
      <div class="head-bg">
        <img :src="detail.image_bg" alt="" />
        <div class="head-name">
          {{ detail.collections_name }}
        </div>
        <!-- {{ changeTime(detail.start_time) }} -->
        <div class="head-btn">敬请期待</div>
      </div>
    </div>
    <div class="active" v-if="showInvite && activityId == 1">
      <div class="">
        活动一：注册并完成实名认证，即可获得 <span class="col-h">看漫数字藏品盲盒空投</span> 1
        份，限量 1000 份！
      </div>
      <div class="">
        活动二：成功邀请3人(注册并实名认证)，即可获得<span class="col-h"
          >9.9元购买《仙武帝尊》系列数字藏品资格</span
        >，限量100名！
      </div>
    </div>
    <div class="login-box">
      <van-icon v-if="false" class="icon" name="cross" @click="goBack" />
      <div class="login-title" v-if="showInvite && activityId != 1">立即注册</div>
      <div class="login-title" v-if="!showInvite">手机号登录</div>
      <div class="login-desc" v-if="!showInvite">提示：未注册手机号登录后会自动注册</div>
      <div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="mobile"
            type="tel"
            name="mobile"
            class="field"
            clearable
            :border="false"
            @blur="mobileBlur"
            placeholder="输入手机号"
          />
          <div v-if="mobileHintShow" class="phone-warn"><span>请输入正确的手机号</span></div>
          <div class="verify-box">
            <div>
              <van-field
                center
                clearable
                class="verify"
                placeholder="输入验证码"
                v-model="verify_code"
                @blur="verifyBlur"
                :border="false"
              />
              <div v-if="verifyHint" class="verify-warn">
                <span>{{ verifyHint ? verifyHint : '请输入正确的验证码' }}</span>
              </div>
            </div>
            <van-button
              size="small"
              class="sendverify"
              type="default"
              @click="clickSendSms"
              hairline
              :disabled="complainSMS.disabled"
              >{{ complainSMS.text }}</van-button
            >
          </div>

          <van-checkbox v-model="checked" @change="checkChange"
            >我已同意<a href="https://static.321mh.com/html/h5/index.html?id=139"
              >《用户服务协议》</a
            >及<a href="https://static.321mh.com/html/h5/index.html?id=138">《隐私协议》</a>
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
            </template>
          </van-checkbox>

          <van-button
            block
            plain
            native-type="submit"
            type="default"
            class="submit-btn"
            :disabled="submitDisabled"
            >{{ showInvite ? '注册' : '提交' }}</van-button
          >
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { sendSms, login, addscanlog } from '@/service/user';
import { nftUtils, localStorageUtil } from '../utils';
import moment from 'moment';

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      mobile: '',
      verify_code: '',
      mobileHintShow: false, //显示手机号错误提示
      haveErify: false, //是否填写了验证码
      verifyHint: '', //显示验证码错误提示
      submitDisabled: true,
      invite_user_id: null, //邀请的id
      detail: {}, // 藏品
      showTime: null,
      complainSMS: {
        text: '获取验证码',
        disabled: true,
        isCountDown: false
      },
      checked: false,
      showInvite: false, // 是否为邀请
      activityId: route.query.activityId
    });

    onMounted(async () => {
      if (route.query.invite_user_id) {
        getList();
        state.showInvite = true;
        state.invite_user_id = route.query.invite_user_id;
      }
    });
    // 关联用户-分佣
    const addUser = from_user_id => {
      addscanlog({
        user_id: from_user_id
      }).then(res => {
        console.log(res);
        Toast(message);
      });
    };
    const changeTime = time => {
      let t = diffTime(time);
      if (t > 0) {
        return '敬请期待';
      } else {
        if (state.detail.status == 2) {
          return '敬请期待';
        } else {
          return 111;
        }
      }
    };
    const getList = async () => {
      await store.dispatch('getHomeList');
      state.detail = store.state.homeList[0];
    };
    const onSubmit = async () => {
      nftUtils.setcnzzRecordByBtn('提交登录');
      const params = {
        mobile: state.mobile,
        verify_code: state.verify_code
      };
      if (state.activityId == 1) {
        params.invite_user_id = state.invite_user_id;
      } else {
        params.invite_code = state.invite_user_id;
      }
      if (localStorageUtil.get('utm_source')) {
        params.utm_source = localStorageUtil.get('utm_source');
      }
      const { data, status, message } = await login(params);
      if (status === 0 && data) {
        if (message && state.showInvite) {
          Toast(message);
        }
        store.commit('loginInfo', {
          loginInfo: data
        });
        localStorageUtil.set('accessToken', data.access_token);
        if (data.id_verify) {
          goBack();
        } else {
          let path;
          // let path = state.showInvite
          //   ? `/authentication?invite_user_id=${state.invite_user_id}`
          //   : '/authentication';
          if (state.showInvite) {
            path = {
              path: '/authentication',
              query: { invite_user_id: state.invite_user_id, activityId: state.activityId },
              replace: true
            };
          } else {
            path = { path: '/authentication', replace: true };
          }
          router.push(path);
        }

        if (route.query.from_user_id) {
          addUser(route.query.from_user_id);
        }
      } else if (message) {
        state.verifyHint = message;
        Toast(message);
      }
    };

    const mobileBlur = () => {
      state.mobileHintShow = !nftUtils.mobileTest(state.mobile);
      state.complainSMS.disabled = state.mobileHintShow || state.complainSMS.isCountDown;
      state.submitDisabled = state.mobileHintShow || !state.haveErify || !state.checked;
    };

    const verifyBlur = () => {
      state.haveErify = !!state.verify_code;
      state.verifyHint = !state.haveErify ? '验证码不能为空' : '';
      state.submitDisabled = state.mobileHintShow || !state.haveErify || !state.checked;
    };

    const checkChange = () => {
      state.submitDisabled = state.mobileHintShow || !state.haveErify || !state.checked;
    };

    const goBack = () => {
      if (route.query.from_user_id) {
        router.push({ path: '/home', replace: true });
      } else {
        if (window.history.length < 2) {
          if (state.showInvite) {
            nftUtils.setcnzzRecordByBtn('通过邀请码注册');
          }
          if (nftUtils.isInWx()) {
            window.location.href = window.location.origin + '/home';
          } else {
            router.push({ path: state.showInvite ? '/home' : '/user', replace: true });
          }
        } else {
          router.go(-1);
        }
      }
    };
    const clickSendSms = () => {
      nftUtils.setcnzzRecordByBtn('点击发送验证码');
      const tel = state.mobile;
      console.log(tel);
      if (!nftUtils.mobileTest(tel)) {
        Toast('请输入正确的手机号码');
        return;
      }
      sendSms({ mobile: state.mobile })
        .then(res => {
          if (res.status === 0) {
            Toast('发送成功~');
            return res.data;
          } else {
            Toast({ message: res.message });
          }
        })
        .catch(err => {
          Toast(err.message || '提交失败，稍后再试。');
        })
        .then(() => {
          nftUtils.countDownFn(state.complainSMS);
        });
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    return {
      ...toRefs(state),
      goBack,
      mobileBlur,
      verifyBlur,
      checkChange,
      clickSendSms,
      onSubmit,
      getList,
      changeTime,
      diffTime,
      activeIcon: require('../imgs/icon/icon_fwxy_yixuan20.png'),
      inactiveIcon: require('../imgs/icon/icon_fwxy_weixuan20.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.col-h {
  color: $primary;
}
.active {
  margin: 34px 20px 0px;
  font-size: 14px;

  line-height: 25px;
}
.head {
  margin: 0 16px;
  position: relative;

  .head-title {
    font-weight: 500;
    background-image: -webkit-linear-gradient(bottom, #a78b5f, #ffeccd, #ffc466);
    font-size: 22px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 24px 0 16px;
  }
  .head-bg {
    @include bis('../imgs/comm/icon_spbg.png');
    padding: 17px 17px 47px 17px;
    font-size: 0px;
    img {
      width: 311px;
    }
  }
  .head-name {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    margin-top: 21px;
  }

  .head-btn {
    position: absolute;
    bottom: -17px;
    // margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 0%);
    background-image: url('../imgs/comm/pic_button_cmh_2.png');
    background-size: 100%;
    color: #fff;
    display: flex;
    width: 272px;
    height: 45px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
}
.login-box {
  position: absolute;
  width: 100%;
  font-size: 20px;
  padding-bottom: 76px;

  .icon {
    margin-top: 50px;
    margin-left: 5%;
  }
  .login-title {
    padding-top: 40px;
    margin-top: 34px;
    margin-left: 5%;
    font-size: 18px;
  }
  .login-desc {
    margin-left: 5%;
    font-size: 14px;
    opacity: 0.8;
  }
  .field {
    margin-top: 20px;
    width: 335px;
    height: 44px;
    background-color: $primaryc;
    left: 50%;
    transform: translateX(-50%);
    --van-field-label-color: #fff;
    --van-field-input-text-color: #fff;
    --van-field-placeholder-text-color: #6d6979;
  }
  .phone-warn {
    color: $redc;
    font-size: 12px;
    margin-left: 20px;
    margin-top: 10px;
    height: 12px;
  }
  .verify-warn {
    color: $redc;
    font-size: 12px;
    margin-top: 10px;
    height: 12px;
  }
  .verify-box {
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    --van-field-input-text-color: #fff;
    --van-field-placeholder-text-color: #6d6979;
  }
  .verify {
    width: 171px;
    height: 44px;
    background-color: $primaryc;
  }
  .img-icon {
    height: 20px;
  }
  .van-checkbox {
    margin-top: 20px;
    margin-left: 20px;
    --van-checkbox-label-color: $fc;
    opacity: 1;
    font-size: 12px;
    a {
      opacity: 1;
      color: $primary;
    }
  }
  .sendverify {
    margin-left: 12px;
    width: 152px;
    height: 44px;
    font-size: 14px;
    --van-button-default-background-color: $bc;
    --van-button-plain-background-color: $bc;
    --van-button-default-border-color: $fc;
    --van-button-border-width: 1px;
    --van-button-default-color: $fc;
    --van-button-border-radius: 4px;
  }
}
</style>
